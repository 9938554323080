import React from 'react';
import { Div } from './styles';
import jakaBlack from '../../../../images/gifs/GraciasFooter.gif';

function Footer() {
  return (
    <Div>
      <span>
        <img alt="logo" src={jakaBlack} />
      </span>

      <span>Controladora JAKA SAPI de CV</span>

      <span>(C) JAKA 2022.</span>
    </Div>
  );
}

export default Footer;
