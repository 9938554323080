import styled from 'styled-components';

export const Intro = styled.div`
  width: 100%;
  margin-bottom: 40px;

  & .title {
    margin-top: 50px;
    margin-bottom: 20px;
    font-family: 'Bw Seido Round';
    font-size: 40px;
    text-align: center;
  }

  & .description {
    font-family: 'Roboto Light';
    font-size: 18px;
    text-align: justify;
  }
`;

export const Gif = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-top: 2rem;

  & img {
    width: 100%;
  }
  & span {
    font-family: 'Bw Seido Round Light';
    font-size: 48px;
  }
`;

export const Div = styled.div`
  width: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`;