import { useEffect, useState } from 'react';
// import ReactGA from 'react-ga4';
import Container from 'react-bootstrap/Container';
import Feed from '../../components/Feed'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import { FaArrowLeft } from 'react-icons/fa';

import { Div } from './styles';
import Banner from '../../components/Banner';

const FeedsPage = () => {

  const API = process.env.REACT_APP_API_URL;
  let [feeds, setFeeds] = useState([]);
  const [currFeed, setCurrFeed] = useState([]);
  const [showContainer, setShowContainer] = useState(false);
  const [currId, setCurrId] = useState('');
  const [isHovered, setIsHovered] = useState(false);


  useEffect(() => {

    setShowContainer(false);
    getFeeds();
  }, []);


  async function getFeeds() {
    const res = await fetch(`${API}/read`, { mode: 'cors' })
    let data = await res.json();
    data = data.reverse();
    setFeeds(data);
  };

  async function getSingleFeed(id) {

    const res = await fetch(`${API}/read/${id}`, { mode: 'cors' })
    const data = await res.json();
    return data
  }

  async function handleClick(id) {
    setShowContainer(showContainer === false ? true : false);
    const data = await getSingleFeed(id);
    const newId = data._id.$oid;
    setCurrFeed(data);
    setCurrId(newId);
  }


  return (
    <>
      <Div>
        <Banner content={<div className="title">FEED</div>} />
      </Div>
      <br /><br />
      {
        showContainer ?
          (<div>

            <Container>
              <Row>
                <Col xs={12} md={8} className="mx-auto">

                  <button
                    onClick={() => setShowContainer(false)}
                    className="bg-transparent text-black mr-4 md:mr-10"
                  >
                    <FaArrowLeft size={32} />
                  </button> <br />
                </Col>
              </Row>
            </Container>

            <Feed data={currFeed} currId={currId} />
          </div>)
          : (
            <div className="mb-6">
              <Container>
                <Row>
                  {feeds.map((user) => (
                    <Col md={4} key={user._id} className="p-2 d-flex">
                      <Card
                        className="w-100 h-100"
                        onClick={() => handleClick(user._id)}
                        style={{
                          cursor: 'pointer',
                          boxShadow: isHovered ? "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" : "none",
                          transition: "box-shadow .3s ease-in-out"
                        }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <Card.Body>
                          <Card.Title className="text-center">{user.title}</Card.Title>
                          <Card.Text>
                            {user.description.length > 100
                              ? user.description.substring(0, 100) + "..."
                              : user.description}
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-center">
                          <Button variant="outline-dark" size="lg">
                            
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>

            </div>
          )
      }

    </>

  );
};

export default FeedsPage;
