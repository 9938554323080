import { Container, Stack, useMediaQuery } from '@mui/material';
import React from 'react';
// import ReactGA from 'react-ga4';
import BioImage from '../../images/gifs/jaka.gif';
import logo from '../../images/logo-image.svg';
import doodles from '../../images/doodles.svg';
import BioGif from '../../images/gifs/Bio_WarOfArt.gif';
import {
  BigTitle,
  BioDescription,
  Columns,
  Details,
  Div,
  Information,
  Line,
  Logo,
  MaxTitle,
  Picture,
  Quote,
  Section,
  Title,
} from './styles';

function Bio() {
  const matches = useMediaQuery('(max-width: 576px)');

  // React.useEffect(() => {
  //   // Initialize Google Analytics
  //   ReactGA.pageview('/bio', undefined, 'Bio');
  // }, []);

  return (
    <React.Fragment>
      <Container>
        <Stack
          direction={matches ? 'column' : 'row'}
          mx={2}
          my={10}
          spacing={matches ? 4 : 6}>
          <Picture>
            <img alt="bio" src={BioImage} />
          </Picture>

          <BioDescription>
            <Title>Armando Kuroda</Title>
            <Details>
              Fintech-Crypto Entrepreneur, Artist, and Investor.
            </Details>
            <Line />
            <Quote>
              <span>
                “To be present and empowered so that i can help others grow and
                create more trust.
              </span>
              <span>
                I want to remove barriers for latam people so that can access
                opportunities to develop themselves.”
              </span>
            </Quote>
          </BioDescription>
        </Stack>
      </Container>

      <Div>
        <Section>
          <BigTitle>Leadership</BigTitle>

          <Columns>
            <div>
              <ul>
                <li>To create trust </li>
                <li>Financial education </li>
                <li>Improve </li>
                <li>Gratitude </li>
                <li>Recognition </li>
                <li>Biohacking </li>
              </ul>

              <ul className="no-bullets">
                <li>ETH DENVER</li>
                <li>ETH MEXICO</li>
                <li>ETH PARIS DAOS</li>
                <li>DEVCON 5 JAPAN </li>
                <li>DEVCON 6 COLOMBIA</li>
                <li>NFT NEW YORK</li>
                <li>BLOCKCHAIN SUMMIT PANAMA</li>
                <li>BLOCKCHAIN LAND MONTERREY</li>
              </ul>

              <ul className="no-bullets">
                <li>* Blockchain </li>

                <li>Early Crypto adopter 2013 </li>

                <div className="doodles">
                  <img alt="doodle" src={doodles} width={150} />
                </div>
              </ul>
            </div>

            <div>
              <span>+ 15 YEARS DEVELOPING TECH PRODUCTS</span>

              <ul>
                <li>UX Designer </li>
                <li>Gamification </li>
                <li>Financial regulation </li>
                <li>Financial engineering </li>
                <li>System architect </li>
              </ul>

              <ul className="no-bullets">
                <li>Public speaker</li>
                <li>Active crypto community member</li>
              </ul>

              <span>METHODOLOGIES</span>

              <ul>
                <li>Traction </li>
                <li>Profit first </li>
                <li>Flow in business </li>
                <li>scrum </li>
                <li>Octalysis </li>
              </ul>
            </div>
          </Columns>
        </Section>

        <MaxTitle>
          <img alt="gif" src={BioGif} />
        </MaxTitle>

        <Container>
          <Information>
            <br />
            <span>
              For over Fifteen years, Armando has been at the forefront of
              Latin America’s financial innovation boom as an entrepreneur
              and operator. He has been actively involved in the crypto/web3
              space as an investor and builder since 2013, and through his
              journey, he has established a strong network of other founders
              and investors working at the intersection of money and
              technology across LatAm
            </span>

            <span>
              Armando is well known in the Latin American Fintech scene as
              one of the first entrepreneurs who acted on the opportunity of Mexico’s financial regulation
              progress. The novel Savings and Credit Bill (Ley de Ahorro y Crédito Popular) allowed
              Armando to pursue and obtain financial operations licenses along a group of Agribusiness
              investors that included former Senator Carlos Medina Plascencia. As the COO of
              FOMAGRO, Armando deep dived into the rural and popular Mexican banking landscape.
              Alongside FIRA -Mexico’s Central Bank most well managed Trust-fund, Armando
              developed unique financial solutions that suited both the political landscape of
              opportunities and the risk assessments to maintain profitability while serving the
              underserved market.
            </span>

            <span>
              In 2011, he launched his second venture Credilikeme, a lending solutions platform
              targeting young consumers through alternative credit scoring, mobile, and social media
              marketing. With the angel investment of Antonio del Valle Perochena from ORBIA,
              Armando and his cofounders built a gamified personal loan experience that has served
              hundreds of thousands in Mexico. As an early player, he captured and helped shape Fintech
              business opportunities and best practices in the region by partnering with the leading
              accelerator programs and industry leaders such as Village Capital, EFL, Accial Capital and
              STP, as well as digital marketing renowned players such as Google, Facebook, and AWS.
            </span>

            <span>
              With a deeper understanding of the local credit market dynamic, in 2015 he founded
              kobra.red, a last-mile network of agents offering in-real time credit and collections services
              to serve both the nascent fintech industry and well-established brick and mortar financial
              institutions such as BBVA-Bancomer, Gentera, Nacional Monte de Piedad, among others.
              Currently, Kobra is the trusted partner for unicorns in the region such as KAVAK, JEEVES,
              Creditas, and other global customers with operations in Mexico and Colombia.
            </span>

            <span>
              All of this while at the same time gaining exposure to crypto and becoming a passionate
              builder of the community in Mexico. This led Armando to give a talk at Ripple Labs in 2013,
              on the large remittance use case and opportunity. Since then, he invested time, resources,
              and became an early promoter of the benefits of decentralized finance. Early attempts as a
              builder included a Mexican XRP gateway and ATM to sell crypto that had a joystick. He
              continued to experiment with blockchain technology and built “108”, an early DeFi savings
              platform built on Ethereum, and more recently in his crypto ventures, he founded
              Guardianes del Metaverso (GDM), an axie infinity gaming guild in Latin America where he
              experienced first hand the unpredictability of the play-to-earn model, and gained important
              skills to navigate the web3 landscape.
            </span>

            <span>
              As an entrepreneur Armando is constantly invited to perform keynote speeches and panel
              appearances at the leading fintech industry events around the world such as: Money 20/20,
              Blockchain Summit LATAM, Crevolution, Finnosummit, MITA TECH TALKS, etc. His
              entrepreneurship efforts have awarded Armando numerous industry awards that include:
              BBVA Open Talent in 2016, Village Capital peer-selected best project 2015, Best
              Gamification Solution at the World Congress in Barcelona 2016, and the Finnovista HSBC
              hackathon in 2019.
            </span>

            <span>
              Armando started writing angel checks in 2018, but quickly realized that the opportunity for
              financial disruption in the region was too large to not perform investment activities as a
              professional investor, so he founded <a href="https://www.jaka.art" target="_blank" rel="noreferrer">Jaka</a> a super early stage seed-fund focusing on
              creative financial products that disrupt and target emerging markets.
            </span>

            <span>
              With JAKA, Armando has been the first check of leading fintechs and web3 companies that
              include Fondeadora, Sixtant, Zendala and hyper-emerging web3 projects Suarmi and
              Neefter. JAKA is also an early investor of Finerio Connect, TREBEL, Prescrypto, among
              others. JAKAs’ largest investments are Credilikeme and Kobra; having bought Credilikeme’s
              Series A shares, and the total stake of Kobra. Armando now serves as President of the
              board for both companies. He also serves as a Board Member in Sixtant, a market making
              and market neutral high frequency trading firm. JAKA is a Venture Partner for Shift Capital.
            </span>

            <span>
              Armando currently plays at <a href="https://www.jaka.art/jakalabs" target="_blank" rel="noreferrer">JakaLabs</a>, an experiment that aims to bring together a
              community of founders, scientists, artists and innovators who dabble in Fintech, web3 and
              the art world, with the objective of scaling what he has been doing throughout his career:
              “invest in friends and learn together”. In his free time, Armando enjoys practicing meditation
              and biohacking, traveling, collecting art, and spending time in nature. 
            </span>
          </Information>
        </Container>
      </Div>

      <Logo>
        <img alt="logo" src={logo} />
      </Logo>
    </React.Fragment>
  );
}

export default Bio;
