import React, { useState, useEffect } from 'react';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

function Carousel(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageError, setImageError] = useState(false);

  const previous = () => {
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex < 0 ? props.images.length - 1 : newIndex);
  }

  const next = () => {
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex % props.images.length);
  }

  const handleImageError = () => {
    setImageError(true);
  }

  useEffect(() => {
    const interval = setInterval(next, 10000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  if (imageError) {
    return <div className="flex items-center">
      <img src="https://static-00.iconduck.com/assets.00/image-not-supported-icon-1024x1024-l3r6g6o6.png" alt="Imagen no compatible con el navegador" className="mr-4" width="50px" height="50px" />
      <p>Imagen no compatible con el navegador</p>
    </div>;
  }

  return (
    <div className="flex justify-center items-center">
      {props.images.length >= 2 && (
        <button
          onClick={previous}
          className="bg-transparent text-black mr-4 md:mr-10"
        >
          <FaArrowLeft />
        </button>
      )}

      <div className="overflow-hidden">
        <img
          src={props.images[currentIndex]}
          alt="Imagen no compatible con el navegador"
          className="w-full h-full object-contain"
          onError={handleImageError}
        />
      </div>

      {props.images.length >= 2 && (
        <button
          onClick={next}
          className="bg-transparent text-black ml-4 md:ml-10"
        >
          <FaArrowRight />
        </button>
      )}
    </div>
  );
};

export default Carousel;
