import { useEffect, useState } from 'react';
import { Alchemy, Network } from "alchemy-sdk";
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal, Web3Button } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig, useAccount, useConnect, useDisconnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'
import { ethers } from 'ethers';

const projectId = process.env.REACT_APP_PROJECTID
const chains = [arbitrum, mainnet, polygon]
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

const config = {
  apiKey: process.env.REACT_APP_ALCHEMY_APIKEY,
  network: Network.MATIC_MAINNET,
};

const alchemy = new Alchemy(config);

const targetContractAddress1 = "0x9fAaa47D7f0Dc02E3296e72FA8721a7E5671EFb1";
const targetContractAddress2 = "0xFEd9F464927549A226d067aa7aFeE7CEf1981b3A"; 

function Web3() {

  return (
    <WagmiConfig config={wagmiConfig}>
      <ConnectedContent />
    </WagmiConfig>
  );
}
function ConnectedContent() {
  const { address, isConnected } = useAccount();
  const [nfts, setNfts] = useState([]);

  useEffect(() => {
    const fetchNFTs = async () => {
      if (isConnected && address) {
        const nfts = await alchemy.nft.getNftsForOwner(address);
        console.log(nfts.ownedNfts);
        setNfts(nfts.ownedNfts);
      }
    }

    fetchNFTs();
  }, [isConnected, address]);

  return (
    <>
      <div className="sm:flex sm:flex-col sm:justify-center sm:items-center sm:mt-8 lg:flex lg:flex-col lg:justify-center lg:items-center lg:mt-8">
        {isConnected ? (
          <div className="sm:bg-white sm:text-black sm:p-6 sm:rounded-lg sm:shadow-lg sm:mb-8 lg:bg-white lg:text-black lg:p-6 lg:rounded-lg lg:shadow-lg lg:mb-8">
            {nfts.some(nft => nft.contract.address.toLowerCase() === targetContractAddress1.toLowerCase()) ||
              nfts.some(nft => nft.contract.address.toLowerCase() === targetContractAddress2.toLowerCase())
              ? (
                <div>
                  <div className="sm:flex sm:justify-center sm:items-center lg:flex lg:justify-center lg:items-center">
                    <Web3Button className="sm:mt-4 lg:mt-4" />
                  </div>
                  <div className="sm:flex sm:justify-center sm:items-center lg:flex lg:justify-center lg:items-center">
                    <p className="sm:font-semibold lg:font-semibold">You have the NFT: OG Fintech Creator</p>
                  </div>

                  <video className="sm:w-64 sm:h-48 lg:w-80 lg:h-60" controls>
                    <source src="https://bafybeibsiqwdewjolarcaq3h7ffubl75mmazr6qx3vwaauq2etwyfmu5uy.ipfs.nftstorage.link/" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>)
              : (
                <div>
                  <div className="sm:flex sm:justify-center sm:items-center sm:mb-4 lg:flex lg:justify-center lg:items-center lg:mb-4">
                    <Web3Button className="sm:mt-4 lg:mt-4" />
                  </div>
                  <p>You don´t have the NFT: OG Fintech Creator</p>
                  <img className="sm:w-64 sm:h-48 lg:w-80 lg:h-60" src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExMjZrdzlkMWlsaTdnN2I1OTY0Zmg5bGdhOWYyMGhlYzFseWJ6NnljNiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/Te2hTzFEdFaLn8L6oL/giphy.gif" alt="Animated GIF"></img>
                </div>)
            }
          </div>
        ) : (
          <div>
            <div className="sm:flex sm:justify-center sm:items-center lg:flex lg:justify-center lg:items-center">
              <Web3Button className="sm:mt-4 lg:mt-4" />
            </div>
            <h1 className="sm:text-4xl sm:font-bold sm:mb-6 sm:text-black-500 lg:text-4xl lg:font-bold lg:mb-6 lg:text-black-500">Please connect your wallet</h1>
          </div>
        )}

        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      </div>
    </>
  );
}


export default Web3;