import styled from 'styled-components';

import { mediaQuery } from '../../helpers/responsive';

export const Div = styled.div`
  & .banner {
    margin-top: 150px;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      width: 981px;
      ${mediaQuery} {
        width: 100%;
      }
    }

    & span {
      font-family: 'Bw Seido Round';
      text-transform: uppercase;
      color: #fff;
      font-size: 40px;
      margin-left: 50px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  // Small devices
  @media (min-width: 576px) {
    flex-direction: column;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    flex-direction: row;
  }

  & img {
    object-fit: contain;
    margin: auto 20px;
    width: 80%;

    // Small devices
    @media (min-width: 576px) {
      width: 80%;
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
      width: 150px;
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      width: 180px;
    }
  }

  & div {
    font-family: Bw Seido Round;
    font-size: 28px;
  }
`;

export const Container = styled.div`
  // margin: 0 200px;
  width: 981px;
  margin: 0 auto;

  ${mediaQuery} {
    // margin: 0 24px;
    width: 100%;
  }

  & .flex1 {
    margin-bottom: 30px;
    & > a {
      text-decoration: none;
      color: inherit;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > a {
    text-decoration: none;
    color: inherit;
  }
`;

export const Flex2 = styled.div`
  display: flex;
  margin: 30px 0;

  ${mediaQuery} {
    flex-wrap: wrap;
  }

  & > a {
    width: 33%;
    text-decoration: none;
    color: inherit;
    ${mediaQuery} {
      width: 100%;
    }
  }

  & > a:nth-child(2) {
    margin: 0 15px;
  }
`;
