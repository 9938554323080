import React from 'react';

function Tensegrity() {
  return (
    <>
      <style>
        {`
          .iframe-container {
            width: 100%;
            height: 100vh;
            background-color: black;
            position: relative;
          }

          .iframe-container iframe {
            width: 100%;
            height: 100%;
            border: none;
          }

          @media (max-width: 600px) {
            .iframe-container {
              height: 50vh; /* Adjust the height as needed */
            }
          }
        `}
      </style>
      <div className="iframe-container">
        <iframe 
          title="Wix"
          src="https://gm98218.wixsite.com/tensegrity-1" 
          frameBorder="0" 
          scrolling="auto" 
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}

export default Tensegrity;
