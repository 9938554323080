import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from './../Carousel/index.js'
import Linkify from 'react-linkify';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive'

function Feed(props) {
    const API = process.env.REACT_APP_API_URL;
    const [images, setImages] = useState([]);
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 })

    useEffect(() => {
        getSingleFeed(props.currId);
    }, [getSingleFeed, props.currId]);

    async function getSingleFeed(id) {
        const res = await fetch(`${API}/read/${id}`, { mode: 'cors' })
        const data = await res.json();
        await setImages(data.images)
        return data
    }

    // Decorador para cambiar el estilo del enlace
    const linkDecorator = (href, text, key) => (
        <a href={href} key={key} style={styles.link} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );

    const styles = {
        titleContainer: {
            backgroundColor: '#1D1D1B',
            padding: '20px',
            borderRadius: '15px',
        },
        descriptionContainer: {
            backgroundColor: '#1D1D1B',
            padding: '20px',
            borderRadius: '15px',
        },
        title: {
            fontFamily: 'Montserrat, sans-serif',
            color: 'white',
            textShadow: '1px 1px 2px #bbbbbb',
            fontSize: isDesktopOrLaptop ? '3em' : '1.4em',
        },
        description: {
            fontFamily: 'Roboto, sans-serif',
            color: 'white',
            textAlign: 'justify', 
            fontSize: isDesktopOrLaptop ? '1em' : '0.8em',
        },
        link: {
            color: 'white',
            fontWeight: 'bold',
        },
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
            <Container>
                <Row>
                    <Col md={8} xs={12} className="mx-auto">
                        <br />
                        <div style={styles.titleContainer}>
                            <motion.h1
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 2 }}
                                style={styles.title}
                            >
                                {props.data?.title}
                            </motion.h1>
                        </div>
                        <br />
                        {props.data?.description &&
                            <div style={styles.descriptionContainer}>
                                <p style={styles.description}>
                                    <Linkify componentDecorator={linkDecorator}>
                                        {props.data.description}
                                    </Linkify>
                                </p>
                            </div>
                        }  <br />
                        
                        {images.length >= 1 ? (
                            <motion.div
                                initial={{ scale: 0.8 }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 1.5 }}
                            >
                                <Carousel images={images} />
                            </motion.div>) : (<div></div>)}
                        <br /><br /><br />
                    </Col>
                </Row>
            </Container>
        </motion.div>
    );
}

export default Feed;