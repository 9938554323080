import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import Sketch from 'react-p5';
// import ReactGA from 'react-ga4';
import ReactDOM from "react-dom";
import { Intro } from './styles';
import Button from 'react-bootstrap/Button';
//const { Client } = require('pg')
// const pgp = require('pg-promise')();

function MapaWeb3() {

  // React.useEffect(() => {
  //   ReactGA.pageview('/mapaweb3', undefined, 'MapaWeb3');
  // }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');



  const handleSubmit = (event) => {
    event.preventDefault();

    // First, check if the user entered a name and email
    if (!name || !email) {
      return alert('Porfavor ingresa tu nombre y correo');
    }

    // Next, check if the email is valid
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      return alert('Porfavor ingresa un correo válido');
    }

    console.log(name); 
    console.log(email);
    // http://35.194.44.8/mapaweb3/?name=Bernardo&email=lord@gmail.com
    fetch('http://35.194.44.8/mapaweb3',{
      mode: "no-cors",
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({
        name: name,
        email: email,
      }) })
      .then(response => response.text()).then(text => console.log(text))
    // If the user has entered a valid name and email,
    // generate a URL for the PDF file and download it
    const pdfUrl = `https://bafybeig6iitilbkm3a7lptcr224lodzrxyf2kbo5red7mw2mfgwlwnmiau.ipfs.nftstorage.link`;
    downloadFile(pdfUrl);
  };

  const downloadFile = (url) => {
    // Use the browser's built-in method to download the file
    window.open(url);
  }



  return (
  <div style={{backgroundColor:'black'}}>
    <Container>
  <br />
  <div  style={{textAlign:'center'}}>
    <form onSubmit={handleSubmit}>
      <label htmlFor="name"></label>
      <input
        type="text"
        name="name"
        value={name}
        placeholder="Ingresa tu nombre"
        onChange={(event) => setName(event.target.value)}
      />
      <label htmlFor="email"></label>
      <input
        type="text"
        name="email"
        value={email}
        placeholder="Ingresa tu correo"
        onChange={(event) => setEmail(event.target.value)}
      /> <br />
      <Button variant="success" type="submit">Descargar MapaWeb3</Button>{' '}
    </form><br />
  </div>

    
          <embed src="https://bafybeig6iitilbkm3a7lptcr224lodzrxyf2kbo5red7mw2mfgwlwnmiau.ipfs.nftstorage.link/#toolbar=0"  width="100%" height="1150px"/>
    </Container>
  </div>
  );
};



const rootElement = document.getElementById("root");
ReactDOM.render(<MapaWeb3 />, rootElement);
export default MapaWeb3;





