import EmailIcon from '@mui/icons-material/Email';
import { Container, Stack } from '@mui/material';
import React from 'react';
// import ReactGA from 'react-ga4';
import ContactGif from '../../images/gifs/Contact.gif';
import jakaBlack from '../../images/jaka-black.svg';
import { ContactInfo, Line, Logo, Subtitle } from './styles';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Contact() {
  // React.useEffect(() => {
  //   // Initialize Google Analytics
  //   ReactGA.pageview('/contact', undefined, 'Contacto');
  // }, []);

  return (
    <Container>
      <Stack my="50px" alignItems="center" spacing={2}>
        <img alt="trust" src={ContactGif} width="100%" />

        <Subtitle>flow in business</Subtitle>

        <Stack alignItems="center" direction="row" mt="2rem">
          <Logo>
            <img alt="logo" src={jakaBlack} />
          </Logo>

          <Line />

          <ContactInfo>
            <div>
              <EmailIcon /> <span>jaka@jaka.art</span>
            </div>

            {/*
          <span>
            <WhatsAppIcon /> +52 1 685 432 2053
          </span>
          */}
          </ContactInfo>
        </Stack>
      </Stack>
    </Container>
  );
}

export default Contact;
